@media (max-width: 1200px) {
  
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
    :root{
      --dark-color:#0378A6;
      --light-blue:#B3E7F2;
      --grey-white:#F2F2F2;
      --text-color:black;
      --mob-coeff: calc((100vw / 414) * (100vh / 896));
      --mobw-coeff: calc(100vw / 414);
      --mobh-coeff: (100vh / 896);

      --testing:calc(100vw / 414 );

      --testing3:calc(100vh / 896 * 5);

      --testing2:calc((var(--testing) + var(--testing3)) * 20);

      --mob-h-size: calc(var(--mobw-coeff) * 16);
      --mob-text-size: calc(12 * var(--mobw-coeff));
      --test:calc(10px + 20px);
      --mob-svg-size: calc(50 * var(--mobw-coeff));
      --grid-row-size:calc(var(--mob-svg-size) + 10px + var(--mob-h-size) * 2 + 10px + var(--mob-text-size) * 7 + 10px);
    
    }
    *{
        margin: 0;
        
        padding: 0;
    }
    .test-var{
      background-color: black;
      height: var(--mob-h-size);
      height: 16px;
      width:var(--testing2);

    }
    .test-var2{
      background-color: cyan;
      height: 16px;
      width:120px;

    }
    .mob-space{
      height: 3vh;
    }
    .space3{
      height: 10px;
      background-color: var(--grey-white);
    } 
    body {
      width: 100vw;
      
        background-color: var(--grey-white); 
        color: #222;
        font-family: "Montserrat", sans-serif;
        font-weight: 300; 
        
    
    }
    .polosa{
      color: aquamarine;
      background-color: bisque;
      width: 414px;
      height: 20px;
    }
    .polosa2{
      color: aquamarine;
      background-color: rgb(176, 97, 0);
      width: 360px;
      height: 20px;
    }
    
    .mob-carousel{
      
        background-color: var(--light-blue); 
         
        

        
        color: var(--text-color);
        align-self: center;
        max-width: 100%;
       overflow: hidden;
       background-size: cover;
       background-position: center center;
       margin: 0px auto;
       
    
    
       position: relative;
    }
    
    .mc-item{
      width: 100vw;
      height: calc(100vw/2*3);
        
    }
    .mc-image{
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      height: calc(100vw/2*3);
        left: 0;
      filter: brightness(30%);


        object-fit: cover;
     
    }
    .c-imager{
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      
        position: absolute;
        right: 0;
        margin-right: 0px; 
        
        object-fit: cover;
     
    }
    
    .mob-carousel-caption{
      top:0%;
      display: flex;

      justify-content: center;
      align-items: center;

    }

    .mob-carousel-header{
      font-family: "Montserrat", sans-serif;
        font-weight: 500; 
      color: white;
    }
    .mob-carousel-text{
      font-size:calc(16px + 8 * (100vw - 320px) / 880);

      font-family: "Montserrat", sans-serif;
        font-weight: 300; 

    }

    
    
    
    #containerr{
      height: 500px;
      width: 50%;
      overflow: hidden;
      float: left;
    }
    
    .skewr{ 
        
        margin-top: -20px;
        padding: 10px;
        float: right;
        width: 30%;
        height: 500px;
        shape-outside: polygon(100% 80% , 100% 0%, 0 0%);
      }
      
    
      .mcards{
       
        width: 100vw; 
        align-self: center;
        
    
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(7, 260px) ;
        
    
        justify-items: center;
      
       margin: 20px auto;
    
    
       position: relative;
      }
       .mcardImage{
        width: 160px;
        height: 160px;
       }
       .mcardrow{
        align-items: center;
       }
     
       .mcard {
        
        
        border: 1px solid var(--dark-color);
        align-self: center; 
        width: 180px;
        height: 240px;
        max-height: 330px;
        background-color: var(--white);
        border-radius: 10px; 
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.08),
          -4px -4px 12px rgba(0, 0, 0, 0.08);
        overflow: hidden;
        transition: all 0.3s;
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px;
        background-color: var(--grey-white);
    
      }
      .mcard-last{
        width: 1200px; 
        height: 80px;
        margin: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        
      }
    
      
      .mcard:hover {
        transform: translateY(-10px);
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1),
          -4px -4px 12px rgba(0, 0, 0, 0.08);
      }
      
      .mcard-image-container {
        width: 100%;
        height: 64%;
        border-radius: 10px;
        margin-bottom: 12px;
        overflow: hidden;
        background-color: #8C8C8C;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .image-icon {
        font-size: 40px;
      }
      
      .mcard-title,.mcard-title-last {
        margin: 0;
        font-size: var(--mob-h-size);
        font-family: "Montserrat", sans-serif;
      font-weight: 600;
      color: var(--text-color);
        cursor: default;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }
      .mcard-title-last{
        
        width: auto;
        height: auto;
        background-color: var(--grey-white);
        border-radius: 10px;
        align-self: center; 
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.08),
          -4px -4px 12px rgba(0, 0, 0, 0.08);
        border: 1px solid var(--dark-color);
        box-sizing: border-box;
        padding: 10px;
        background-color: var(--grey-white);
        font-size: 25px; 
        transition: all 0.3s;
        
    
      }
      .mcard-title-last:hover {
        transform: translateY(-10px);
        
        cursor: pointer;
        
      }
      .catLink{
        color: var(--dark-color);
        text-decoration: none;
      }
      
      .mcard-des {
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        
        line-clamp: 3; 
        margin: 0;
        font-size: var(--mob-text-size);
        font-family: "Montserrat", sans-serif; 
      font-weight: 600;
        color:  var(--dark-color);
        cursor: default;
      }
      .mcard-icon{ 
        width: 100%;
        height: 64%;
        
        border: 1px solid var(--dark-color);
        margin-bottom: 12px;
        overflow: hidden;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        color: #8C8C8C;
      } 
    
      .mzakaz{
        width: 100vw;
        
        margin: 0px auto;
        
          position: relative;
          text-align: center;
          color: white;
          
      
      }
      .morder-image{ 
       
        
        width: 100vw;
        
        
        border-top-left-radius: 10px; 
        border-top-right-radius: 10px; 
    
    }
    
    .mhzakaz{
      color: var(--grey-white);
      position: absolute;
      top: 20%;
      
      transform: translate(0%, -50%);
      
        font-family: "Montserrat", sans-serif; 
        font-size: calc(var(--mob-h-size) * 1.2);
        font-weight: 500;
    }
    .mcaption-zakaz{
      color: var(--grey-white);
      margin-top: auto;
      position: absolute;
      left: 16%;
      transform: translate(0%, -50%);
      
        font-family: "Montserrat", sans-serif; 
        font-size: calc(var(--mob-text-size) * 1.2);
        font-weight: 400;
        top: 60%;
       
    }
    
    .mymap{
      align-self: center; 
      
      margin: 0px auto;
      background-color: var(--light-blue);
    
       
     
    }
    .mob-map{
      width: 100vw;
      height:70vh;
    }
    .mobmheader{
      
      font-size: var(--mob-h-size);
      color: var(--text-color);
      font-weight: 550;
    
    }
    .mobmtext{
      margin-top: 50px;
      margin-left: 20px;
      color: var(--text-color);
      font-family: "Montserrat", sans-serif; 
      font-size:var(--mob-text-size);
      font-weight: 500;
    
    }
    .contacts{
      color: var(--dark-color);
    }
    
    .ymap-wrapper{
      
    }
    
    .mcards-wrapper {
      margin-top: 20px;
      border-top: 3px solid var(--dark-color);
      border-bottom: 3px solid var(--dark-color);
        width: 100%;
        height: 100%;
        background: linear-gradient(
            45deg,
            transparent 33.33%,
            var(--light-blue) 33.33%,
            rgba(0, 0, 0, 0.1) 66.66%,
            transparent 66.66%
          ), 
          var(--light-blue); 
        background-size: 20px 20px;
      }
    .our-clients{
      color: var(--text-color);
      height: 400px;
      background-color: var(--grey-white);
      position: relative;
      text-align: center;
      display: grid;
        align-items: center;
        justify-content: center;
    
      
    }
    .hClients{ 
      margin-top: 30px;
      font-family: "Montserrat", sans-serif; 
      font-size: 33px;
      font-weight: 500;
    }
    .clients-image{
      width: 1000px;
     
      
        
      position: relative;
    }
    .mob-why-us{
      width: 100vw;
      
      color: var(--text-color);
      margin: auto;
      text-align: center;
      position: relative;
    }
    .mob-h1us{ 
      
      margin-top: 40px;
      font-family: "Montserrat", sans-serif; 
      font-size: calc(1.2 * var(--mob-h-size));
      font-weight: 500;
       
    }
    .mob-h2us{
      margin-top: 10px;
      
      margin: 10px auto; 
    
      font-family: "Montserrat", sans-serif;  
      font-size: calc(1.2 * var(--mob-text-size));
      font-weight: 300;
    
    }
    .mob-advantages{
      display: grid;
      width: 95vw;
      height: auto;
      margin-top: 40px;
        
        
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(3,var(--grid-row-size));
    
        column-gap: 20px;
        row-gap: 10px;
        left: 10px;
        justify-items: center;
      
       
    
    
       position: relative;
        
       
    }
    .mob-advantage{
      height: 50vw;
      display: grid;
      
      grid-template-rows: calc(var(--mob-svg-size)  ) calc(var(--mob-h-size) * 2 + 20px ) calc(var(--mob-text-size) * 5 + 10px );
      justify-items: center;
    }
    .svg-wrapper{
      height: 50px;
    }
    .mob-svg{
      width: var(--mob-svg-size);
      height: var(--mob-svg-size);

    }
    .mob-advh{
      font-family: "Montserrat", sans-serif;  
      color: var(--text-color);
      font-weight: 450;
      align-self: center;
      font-size: var(--mob-h-size);
    }
    .mob-adv-caption{
      font-family: "Montserrat", sans-serif;
      color: var(--text-color);
      font-size: var(--mob-text-size);
      font-weight: 300;
      
      
    }
    



  }