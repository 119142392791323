@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');



@media (max-width: 1200px) {

    .header {
        background-color: var(--grey-white);
    }
    * {
        box-sizing: border-box;
    }
    
    div {
        display: block;
        unicode-bidi: isolate;
    }
    
    .header:after, .header-inner:after {
        content: "";
        display: table;
        clear: both;
    }  

    .header-inner {
        margin: 0 auto;
        padding: 0 20px;
    }
    .logo {
        margin: 8px 0 0 0;
        padding: 10px 0;
        min-height: 55px;
        float: left;
        width: 70%;
    }
    a:-webkit-any-link {
        color: -webkit-link;
        cursor: pointer;
        text-decoration: underline;
    }
    .logo img {
        height: 51px;
        max-height: 80px;
        margin: 0;
    }
    a img {
        border: 0;
    }

    
    img {
        overflow-clip-margin: content-box;
        overflow: clip;
    }
    
    a:-webkit-any-link {
        color: -webkit-link;
        cursor: pointer;
    } 
    nav {
        display: block;
        unicode-bidi: isolate;
    }   
    nav {
        border-top: 2px solid var(--dark-color);
        clear: both;
        font-family: "Montserrat", sans-serif; 
        font-weight: 500;
        font-size: 1em; 
    }
    ul.navigation li {
        display: inline-block;
        line-height: 220%;
    }
  
   
    li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
    }

    ul.navigation {
        margin: 5px 10px 10px 0px;
        padding: 0;
    }
    ul.navigation li a {
        border-bottom: 3px solid var(--dark-color);
        color: var(--dark-color);
        margin: 0 13px 0 0;
        padding: 5px 0;
        text-decoration: none;
    }
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }

}