@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@media (min-width: 1200px) {

.navb1{
    background-color: var(--grey-white); 
    margin: 0 auto; 
    border-bottom: 3px solid var(--dark-color);
   
    height: 70px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    
    
    
} 
.navbcont{
    width: 1200px; 
    align-self: center;
    font-family: "Montserrat", sans-serif; 
    font-size: 18px;
    font-weight: 500;
    display: grid;
    
    grid-template-columns: 290px 400px auto auto auto;
    
    

    justify-items: left;
    
  
   margin:  auto;
   
   


   position: relative;
    
}
.navl1{
     
     margin-left: 5px;
     
     color: black;
     display: grid; 
     grid-template-columns: repeat(2, 1fr);
     justify-items: center;
  
     position: relative;
    cursor: pointer;
    transition: opacity 80ms ease;
}

.navl1:hover{
    opacity: 0.8;
    
}
 
.navl2{
    font-size: 22px;
    
    
    text-decoration: none;
    justify-self: right;
    margin-right: 5px;
    font-weight: 600;
    color: var(--dark-color);
    display: inline; 
   margin-top: 18px;
   cursor: pointer; 
   transition: opacity 80ms ease;
}

.navl2:hover{
   opacity: 0.6;
   
}
.log1{
   

}
.log2{
    
   
}

}




