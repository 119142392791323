@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@media (min-width: 1200px) {

:root{
  --dark-color:#0378A6;
  --light-blue:#B3E7F2;
  --grey-white:#F2F2F2;
  --text-color:black;

}
*{
    margin: 0;
    
    padding: 0;
}
.space1{
  height: 90px;
  background-color: var(--grey-white);
} 
body {
  
    background-color: var(--grey-white); 
    color: #222;
    font-family: "Montserrat", sans-serif;
    font-weight: 300; 
    

}

.my-carousel{
  
    background-color: var(--light-blue); 
     
    width: 1200px;
    
    color: var(--text-color);
    align-self: center;
    
   
   background-size: cover;
   background-position: center center;
   margin: 0px auto;
   


   position: relative;
}

.c-item{
    height: 600px;
    
}
.c-image{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
    position: absolute;
    left: 0;
    height: 100%;
    object-fit: cover;
 
}
.c-imager{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  
    position: absolute;
    right: 0;
    margin-right: 0px; 
    height: 100%;
    object-fit: cover;
 
}



.ccaption1{
    
   position: absolute;
    
    top: 80px;
    right: 20px;
    


}

.ccaption2{
    
   position: absolute;
    
    top: 80px;
    left: 20px;
    


}
.hc1,.hc2{
    font-size: 35px;
    font-family: "Montserrat", sans-serif;
    font-weight: 550;
    
  
}
.hc2{
  margin-left: 10px;
}
.txt,.txtr {
  word-break: break-word;
  padding: 10px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 20px;
}


   
.skew {    
    margin-top: -20px;
    padding: 10px;
    float: left;
    width: 30%;
    height: 500px;
    
    shape-outside: polygon(0% 100% , 100% 0%, 0 0%);
}


.skew2 {    
    margin-top: -20px;
    padding: 10px;
    float: right;
    
}
#container{
  height: 500px;
  width: 50%;
  overflow: hidden;
  float: right;
}





#containerr{
  height: 500px;
  width: 50%;
  overflow: hidden;
  float: left;
}

.skewr{ 
    
    margin-top: -20px;
    padding: 10px;
    float: right;
    width: 30%;
    height: 500px;
    shape-outside: polygon(100% 80% , 100% 0%, 0 0%);
  }
  

  .cards{
    height: 1400px;  
    width: 1200px; 
    align-self: center;
    

    display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-template-rows: repeat(4, 320px) ;
    

    justify-items: center;
  
   margin: 50px auto;


   position: relative;
  }
   .cardImage{
    width: 150px;
    height: 150px;
   }
   .cardrow{
    align-items: center;
   }
 
   .card {
    
    
    border: 1px solid var(--dark-color);
    align-self: center; 
    width: 230px;
    height: 280px;
    max-height: 330px;
    background-color: var(--white);
    border-radius: 10px; 
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.08),
      -4px -4px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: all 0.3s;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--grey-white);

  }
  .card-last{
    width: 1200px; 
    height: 80px;
    margin: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    
  }

  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1),
      -4px -4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .card-image-container {
    width: 100%;
    height: 64%;
    border-radius: 10px;
    margin-bottom: 12px;
    overflow: hidden;
    background-color: #8C8C8C;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-icon {
    font-size: 40px;
  }
  
  .card-title,.card-title-last {
    margin: 0;
    font-size: 17px;
    font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: var(--text-color);
    cursor: default;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
  .card-title-last{
    
    width: auto;
    height: auto;
    background-color: var(--grey-white);
    border-radius: 10px;
    align-self: center; 
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.08),
      -4px -4px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--dark-color);
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--grey-white);
    font-size: 25px; 
    transition: all 0.3s;
    

  }
  .card-title-last:hover {
    transform: translateY(-10px);
    
    cursor: pointer;
    
  }
  .catLink{
    color: var(--dark-color);
    text-decoration: none;
  }
  
  .card-des {
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    
    line-clamp: 3; 
    margin: 0;
    font-size: 13px;
    font-family: "Montserrat", sans-serif; 
  font-weight: 600;
    color:  var(--dark-color);
    cursor: default;
  }
  .card-icon{ 
    width: 100%;
    height: 64%;
    
    border: 1px solid var(--dark-color);
    margin-bottom: 12px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: #8C8C8C;
  } 

  .zakaz{
    width: 1200px;
    height: 700px;
    margin: 0px auto;
    
      position: relative;
      text-align: center;
      color: white;
      
  
  }
  .order-image{ 
   
    
    width: 1200px;
    height: 700px; 
    
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 

}

.hzakaz{
  color: var(--grey-white);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  
    font-family: "Montserrat", sans-serif; 
    font-size: 35px;
    font-weight: 500;
}
.caption-zakaz{
  color: var(--grey-white);
  margin-top: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  
    font-family: "Montserrat", sans-serif; 
    font-size: 25px;
    font-weight: 400;
    top: 60%;
   
}

.ymap{
  align-self: center; 
  width: 1200px;
  height: 550px;
  margin: 0px auto;
  background-color: var(--light-blue);
  display: grid;
  grid-template-columns: 800px auto;
  grid-template-rows: 600px;
   
 
}

.mheader{
  
  font-size: 30px;
  color: var(--text-color);
  font-weight: 550;

}
.mtext{
  margin-top: 50px;
  margin-left: 20px;
  color: var(--text-color);
  font-family: "Montserrat", sans-serif; 
  font-size: 20px;
  font-weight: 500;

}
.contacts{
  color: var(--dark-color);
}

.ymap-wrapper{
  
}

.cards-wrapper {
  border-top: 3px solid var(--dark-color);
  border-bottom: 3px solid var(--dark-color);
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        transparent 33.33%,
        var(--light-blue) 33.33%,
        rgba(0, 0, 0, 0.1) 66.66%,
        transparent 66.66%
      ), 
      var(--light-blue); 
    background-size: 20px 20px;
  }
.our-clients{
  color: var(--text-color);
  height: 400px;
  background-color: var(--grey-white);
  position: relative;
  text-align: center;
  display: grid;
    align-items: center;
    justify-content: center;

  
}
.hClients{ 
  margin-top: 30px;
  font-family: "Montserrat", sans-serif; 
  font-size: 33px;
  font-weight: 500;
}
.clients-image{
  width: 1000px;
 
  
    
  position: relative;
}
.why-us{
  width: 1200px;
  height:750px;
  color: var(--text-color);
  margin: auto;
  text-align: center;
  position: relative;
}
.h1us{ 
  
  margin-top: 40px;
  font-family: "Montserrat", sans-serif; 
  font-size: 33px;
  font-weight: 500;
   
}
.h2us{
  margin-top: 10px;
  width: 1000px;
  margin: 10px auto; 

  font-family: "Montserrat", sans-serif;  
  font-size: 20px;
  font-weight: 300;

}
.advantages{
  display: grid;
  height: 550px;
  width: auto;
  margin-top: 40px;
    
    
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: repeat(2, 1fr);

    column-gap: 20px;
    
    justify-items: center;
  
   


   position: relative;
    
   
}
.advantage{
  
   
  
}
.advh{
  font-family: "Montserrat", sans-serif;  
  color: var(--text-color);
  font-weight: 450;
  margin-top: 10px;
  font-size: 22px;
}
.adv-caption{
  font-family: "Montserrat", sans-serif;
  color: var(--text-color);
  
  font-weight: 300;
  margin-top: 5px;
  font-size: 17px;
}
}